export default {
    'LOGIN': {
        'TITLE': 'Log in to akii',
        'SUBTITLE': 'Please enter your cell phone number including <br>country code:',
        'SUBTITLE_OTHER_INFO': '(You can only log in to this website if you<br>already have an account on the akii app.)',
        'FOOTER_INFO': 'Want to create an account? You can register in<br>the akii app.',
        'FOOTER_OTHER_INFO': 'Apple and the Apple logo are trademarks of Apple Inc., registered<br> in the U.S. and other countries.<br>Google Play and the Google Play logo are trademarks of Google LLC.',
        'LOGIN': 'Log in',
        'CONTINUE': 'Continue',
        'ERROR': {
            'NETWORK_ERROR': 'There is a problem with the internet connection.'
        }
    },
    'VERIFY_TOKEN': {
        'TITLE': 'Confirmation Code',
        'SUBTITLE': 'We have sent a confirmation code via SMS to<br>this number: {phoneNumber}.',
        'SUBTITLE_THIRD_BLOCK': 'You have not received an SMS? Wait a moment or<br>request a new code.',
        'CODE': 'Confirmation Code',
        'ERROR': {
            'WRONG_CODE': 'You entered a wrong code. Please try again.'
        }
    },
    'LOCKS_GROUPS':{
        'ALREADY_IN_A_GROUP': 'already in a group',
        'ALREADY_IN_THE_GROUP': 'already in this group',
        'WILL_BE_MOVED_IN_THIS_GROUP': 'will be moved in this group',
        'WILL_BE_REMOVED': 'will be removed from this group',
        'WILL_BE_MOVED_HERE': 'will be moved to this group',
        'DELETE_HEADER': 'Delete group?',
        'DELETE_TEXT':'Do you really want to delete the group {groupName}? The action cannot be undone.',
        'CHOSEN_NOT_DELETED':'The chosen locks will not be deleted.'
    },
    'USERS_GROUPS':{
        'ALREADY_IN_A_GROUP': 'already in a group',
        'ALREADY_IN_THE_GROUP': 'already in this group',
        'WILL_BE_MOVED_IN_THIS_GROUP': 'will be moved in this group',
        'WILL_BE_REMOVED': 'will be removed from this group',
        'WILL_BE_MOVED_HERE': 'will be moved to this group',
        'DELETE_HEADER': 'Delete group?',
        'DELETE_TEXT':'Do you really want to delete the group {groupName}? The action cannot be undone.',
        'CHOSEN_NOT_DELETED':'The chosen users will not be deleted.'
    },
    'HOME': {
        'ALL_ROOMS': 'All locks',
        'ALL_USERS': 'All users',
        'PRIVACY_POLICY': 'Privacy policy',
        'TERMS_OF_USE': 'Terms of use',
        'LOGOUT': 'Log out',
        'INFO_TEXT': 'To change accesses, click on the buttons in the table below.',
        'INFO_TEXT_TRANSPONDERS': 'The icons shown are intended for informational purposes and cannot be modified.',
        'INVITATION_SENT': 'Invitation sent',
        'ACCESS': 'Access',
        'ACCESSES': 'Accesses',
        'NO_ACCESS': 'No Access',
        'MIXED': 'Mixed rights',
        'ADMIN': 'Admin',
        'NAME': 'Name',
        'NOTHING': 'No group',
        'ALL': 'All groups',
        'COMPANY': 'Company',
        'PHONE_NUMBER': 'Phone number',
        'GROUP': 'Group',
        'SUBGROUP': 'Subgroup',
        'LOCK_GROUP': 'Lock group',
        'LOCK_GROUPS': 'Lock groups',
        'USER_GROUP': 'User group',
        'USER_GROUPS': 'User groups',
        'GROUP_BY': 'Group by:',
        'SORT_BY': 'Sort by:',
        'VIEW': 'View:',
        'NUMBER_OF_CHANGES': 'no changes | 1 right changed | {count} rights changed',
        'DISCARD': 'Discard',
        'DELETE': 'Delete',
        'OWNER': 'Owner',
        'CARD_NUMBER': 'Card number',
        'EXPIRING_DATE': 'Valid until',
        'USERS': 'Users',
        'SEARCH': 'Search',
        'USER_SEARCH': 'User search',
        'START_DATE': 'Start date',
        'END_DATE': 'End date',
        'TRANSPONDERS': 'Transponders',
        'ALERT': {
            'TITLE': 'Welcome!',
            'NO_USERS': 'There are no users yet. Add the first user.',
            'INFO': '<div>After you added users, you can grant or revoke accesses and admin</div><div>rights for your locks.</div>',
        },
        'MESSAGES': {
            'USER_DELETED': 'The user has been deleted.',
            'GROUP_DELETED': 'The group has been deleted.',
            'GROUP_CREATED': 'The group has been created.',
            'CHANGES_SAVED': 'The changes have been saved.',
            'TOOLTIP_RIGHTS_FROM_OTHERS': '{username} also has permissions to {lockname} from {otherManagersList}.',
            'MISSING_FIELDS': 'Some fields are missing.',
            'USER_ALREADY_PRESENT': 'User already present.'
        },
        'ERROR': {
            'DELETING_USER_FAILED': 'The user cannot be deleted',
            'ERROR_ADDING_YOURSELF': 'You can not add yourself.',
            'ADDING_USER_FAILED': 'An error occurred while adding the user.',
            'UPDATE_ACCESS_FAILED': 'An error occurred while saving the permissions.',
            'SAVE_FAILED': 'An error occurred while saving.',
            'E1201': 'You can not delete the akii support user.',
            'E1202': 'You need to be a manager to delete other user.',
            'E1203': 'The user, you want to delete, does not exist.',
            'E1206': 'The user could not be deleted, because he got access from the akii support.',
        },
        'EDIT_USER': {
            'HEADER': 'Change user data',
        },
        'EDIT_LOCK': {
            'HEADER': 'Lock settings',
            'BODY': 'Change lock name',
            'NAME': 'Lock name',
            'LAST_CHECKED': 'last checked:',
            'BATTERY_NOT_SET': 'No status available yet',
            'BATTERY_OK': 'Ok: do not change',
            'BATTERY_LOW': 'Low: please change',
            'BATTERY_STATUS': 'Battery status',
        },
        'LOCK_GROUP_SELECT': {
            'HEADER': 'Lock groups',
            'NEW_HEADER': 'Create lock group',
            'NEW_HEADER_SUB': 'Create lock subgroup',
            'EDIT_HEADER': 'Edit lock group',
            'EDIT_HEADER_SUB': 'Edit lock subgroup',
            'INFO': 'Give the group a name.',
            'INFO_SUB': 'Give the subgroup a name.',
            'CREATE': 'Create',
            'GROUP_NAME': 'Name',
            'DESCRIPTION': 'Description',
            'DELETE_LOCK_GROUP': 'Delete group',
            'ADD_LOCKS_TO_GROUP': 'Add locks',
            'ADD_LOCKS_INFO': 'Select the locks you want to add to the group:'
        },
        'USER_GROUP_SELECT':{
            'HEADER': 'User groups',
            'NEW_HEADER': 'Create user group',
            'NEW_HEADER_SUB': 'Create user subgroup',
            'EDIT_HEADER': 'Edit user group',
            'EDIT_HEADER_SUB': 'Edit user subgroup',
            'INFO': 'Give the group a name.',
            'INFO_SUB': 'Give the subgroup a name.',
            'CREATE': 'Create',
            'GROUP_NAME': 'Name',
            'DESCRIPTION': 'Description',
            'DELETE_USER_GROUP': 'Delete group',
            'ADD_USERS_TO_GROUP': 'Add user',
            'ADD_USERS_INFO': 'Select the users you want to add to the group:'
        },
        'PROTOCOL' : {
            'BODY': 'Lock protocol',
        },
        'NEW_USER': {
            'HEADER': 'Add user',
            'INFO': 'Please enter the following data of the person you want to give accesses to:',
            'FIRST_NAME': 'First name',
            'LAST_NAME': 'Last name',
            'COMPANY': 'Company',
            'PHONE_NUMBER': 'Phone number ( +49 ... )',
            'ADD_TO_GROUP': 'Add to a group',
            'ADD_TO_GROUP_INFO': 'The user gets the access and admin rights that were assigned to the group.',
        },
        'DELETE_USER': {
            'HEADER': 'Delete user?',
            'BODY': 'Do you really want to delete the user {username}? The action cannot be revoked.'
        },
        'DELETE_CARD': {
            'HEADER': 'Delete card?',
            'BODY': 'Do you really want to delete the transponder {cardid}? The action cannot be revoked.',
            'BODY_ADD': 'Attention: The rights of the transponder are only blocked when you activate (open or close) the corresponding locks with the akii app.'
        },
        'DISCARD_CHANGES': {
            'HEADER': 'Discard changes?',
            'BODY': 'You have edited a user. If you discard the change, the action cannot be undone.'
        },
        'DISCARD_ACCESS_CHANGES': {
            'HEADER': 'Discard rights?',
            'BODY': 'No change | You have changed 1 right, if you discard the change, the action cannot be undone. | You have changed {count} rights. If you discard the changes, the action cannot be undone.'
        }
    }
}
