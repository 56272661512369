<template>
  <div>
    <div>
      <div @click="selectUserGroup()"
           class="lock_group_list_item"
           v-bind:class="{ lock_group_list_item_selected: (!userGroupSelected)}"
      >
        {{ $t('HOME.ALL_USERS') }}
      </div>
    </div>
    <div v-for="group in filterGroupsWithParentNull()" :key="group.id">
      <div @click="selectUserGroup(group)"
           class="lock_group_list_item"
           v-bind:class="{ lock_group_list_item_selected: (group.id===userGroupSelected.id)}"
      >
        {{ group.name }}
      </div>
      <div v-if="group.id===userGroupSelected.id" @click="createSubGroup"  class="lock_group_create_button">
        <span class="plus_icon">+</span><span class="lock_group_create_button_text">{{ $t('HOME.SUBGROUP') }}</span>
      </div>


      <div v-if="group.id===userGroupSelected.id || group.id === userGroupSelected.parent_id">

        <div v-for="subgroup in filterByParentId(group.id)" :key="subgroup.id">

          <div @click="selectUserGroup(subgroup)"
               class="lock_subgroup_list_item"
               v-bind:class="{ lock_subgroup_list_item_selected: (subgroup.id===userGroupSelected.id)}"
          >
            {{ subgroup.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserGroupList',
  props: ['userGroupSelected','userGroups'],

  data() {
    return {}
  },
  methods:{
    filterByParentId: function(parentId){
      let children = this.userGroups.filter(obj => obj.parent_id === parentId)
      children.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      return children;
    },
    selectUserGroup: function(group){
      this.$emit('selectUserGroup', group)
    },
    filterGroupsWithParentNull: function() {
      if (this.userGroups.length > 0) {
        return this.userGroups.filter(obj => obj.parent_id === null);
      }
      return [];
    },
    createSubGroup: function(){
      this.$emit('createSubGroup')
    },
  },
}
</script>

<style scoped>
.lock_group_list_item {
  background-color: #F0ECE5;
  margin-bottom: 20px;
  padding: 25px;
  font-size: 16px;
  font-weight: 400;
  color: #766a53;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lock_group_list_item_selected {
  background-color: #F0ECE5;
  margin-bottom: 20px;
  padding: 25px;
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}
.lock_subgroup_list_item {
  background-color: #FAF8F5;
  margin-bottom: 20px;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: 400;
  color: #766a53;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lock_subgroup_list_item_selected {
  background-color: #FAF8F5;
  margin-bottom: 20px;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: 800;
  color: #000000;
}
.lock_group_create_button{
  padding-left: 18px;
  padding-bottom: 30px;
  color: #c2a977;
  font-weight: 600;
  font-size: 16px;
}
.lock_group_create_button_text:hover{
  text-decoration: underline;
  cursor: pointer;
}
.lock_group_create_button_text:hover{
  text-decoration: underline;
  cursor: pointer;
}
.plus_icon{
  font-size: 24px;
  padding-right: 8px;
  float: left;
}
</style>