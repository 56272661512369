'use strict';

import { request, gql } from 'graphql-request'
import axios from 'axios';
import SessionService from "./SessionService";
const eventstop = require('eventstop')();

export default class ApiService
{
    static client = null;

    static createClient()
    {
        this.client = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL,
            timeout: 90000,
        });
    }

    static request(method, path, body, queryParameters, cancelToken)
    {
        if (this.client === null) {
            this.createClient();
        }

        method = method.toLowerCase();

        return this.client.request({
            url: path,
            method: method,
            headers: {
                //'X-Session-Token': SessionService.getAccessToken(),
                Pragma: 'no-cache'
            },
            params: queryParameters,
            data: body,
            cancelToken: cancelToken
        }).catch(error => {
            throw error;
        });
    }

    static getMe()
    {
        const query = gql`
            query { me {
                first_name
                last_name
                phone
            }}
        `
        return this.graphqlRequest(query);
    }

    static getLoginToken(phoneNumber)
    {
        const query = gql`
            query getLoginToken($phone_nr: String!) {
                getLoginToken(phone_nr: $phone_nr)
            }
        `
        return this.graphqlRequest(query, {phone_nr : phoneNumber});
    }

    static verifyLoginToken(phoneNumber, token)
    {
        return this.request(
            'post',
            '/oauth/token',
            {
                grant_type: 'password',
                client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
                username: phoneNumber,
                password: token,
                scope: 'webapp'
            }
        );
    }

    static getBoxesICanAccess(){
        const query = `{ 
            me { 
                boxesICanAccess {
                    id
                    name
                    battery_state
                    battery_last_reading
                    secured_object_id
                }
            } 
        }`
        return this.graphqlRequest(query, null);
    }

    static getProtocol(securedObjectId, startDate, endDate, cursors) {
        let name = ""
        let value = ""
        if(cursors && cursors.length > 0){
            name = cursors[0].name || ''
            value = cursors[0].value || ''
        }
        const query = `{
            me{
                boxesICanAccess(
                    securedObjectIDFilter: "` + securedObjectId + `"
                ) {
                    lock_activations(
                        cursors: [
                            {
                                name: "` + name + `",
                                value: "` + value + `"
                            }
                        ],
                        start_date: "` + startDate + `",
                        end_date: "` + endDate + `"
                    ) {
                        data {
                            lock_timestamp
                            channel
                            company
                            first_name
                            last_name
                            phone
                            card_company
                            card_note
                            nfc_card_id
                        }
                        cursors {
                            name
                            prev
                            next
                        }
                        latest_reporting_timestamp
                    }
                }
            },
            time
        }`
        const variables = {
            cursors: cursors,
        }

        return this.graphqlRequest(query, variables);
    }

    static getListOfCards(cursor = ''){

        let queryParam = '(cursor: "' + cursor + '")'

        const query = gql`{ 
            me { 
                cardsICanAccessWithCursor${queryParam} {
                    data {
                        id,
                        company,
                        note,
                        nfc_card_id,
                        status,
                        created_at,
                        valid_from,
                        valid_to,
                        grantList {
                            id,
                            name
                        }
                    },
                    cursors {
                        prev
                        next
                        all
                    }
                }
            } 
        }`

        return this.graphqlRequest(query, null);
    }

    static getAllLockGroupsIHaveAccessTo(){
        const query = `
        { allLockGroupsIHaveAccessTo {
                id
                user_id
                name
                description
                company
                parent_id
                root_group_id
                hierarchy_level
                hasLocks
            }
        }
        `
        return this.graphqlRequest(query, null);
    }

    static getAllUserGroupsIHaveAccessTo(){
        const query = `
        { allUserGroupsIHaveAccessTo {
                id
                prefixed_id
                name
                parent_id
                hasUsers
            }
        }
        `
        return this.graphqlRequest(query, null);
    }

    static getCompleteUserList(){
        const query = `
        { usersICanManage {
                users {
                    id
                    first_name
                    last_name
                    user_group_id
                    phone
                    company
                }
            }
        }
        `
        return this.graphqlRequest(query, null);
    }

    static getCompleteLockList(){
        const query = `
        { boxesICanManage(getAll: true)
            {
                boxes {
                    id
                    name
                    lock_group_id
                },
            }}
        `
        let result = this.graphqlRequest(query, null);
        return result;
    }

    static updateLockGroupForBoxes(lockGroupId, assignBoxIds, removeBoxIds){
        const query = `
             mutation(
            $assignBoxIds: [ID!],
            $removeBoxIds: [ID!],
            $lockGroupId: ID!
            ) {
                updateLockGroupForBoxes(
                    assignBoxIds: $assignBoxIds,
                    removeBoxIds: $removeBoxIds,
                    lockGroupId: $lockGroupId
                )
            }
         `;

        return this.graphqlRequest(query, {
            lockGroupId: lockGroupId,
            assignBoxIds: assignBoxIds,
            removeBoxIds: removeBoxIds,
        });
    }


    // updateUserGroupForUsers(assignUserIds: [ID!], removeUserIds: [ID!], userGroupId: ID!): String

    static updateUserGroupForUsers(userGroupId, assignUserIds, removeUserIds){
        const query = `
             mutation(
            $assignUserIds: [ID!],
            $removeUserIds: [ID!],
            $userGroupId: ID!
            ) {
                updateUserGroupForUsers(
                    assignUserIds: $assignUserIds,
                    removeUserIds: $removeUserIds,
                    userGroupId: $userGroupId
                )
            }
         `;

        return this.graphqlRequest(query, {
            userGroupId: userGroupId,
            assignUserIds: assignUserIds,
            removeUserIds: removeUserIds,
        });
    }


    static getBoxAndLockGroups(lockGroup){
        let queryParam = '';
        if(lockGroup){
            queryParam = '(lockGroupId: "' + lockGroup.id + '")';
        }

        const query = `
        { boxesICanManage${queryParam}
            {
                boxes {
                    id
                    name
                    battery_state
                    battery_last_reading
                    secured_object_id
                    lock_group_id
                },
                lockGroupsICanUse {
                    id
                    name
                    description
                    parent_id
                }
            }}
        `
        let result = this.graphqlRequest(query, null);
        return result;
    }

    static getListOfAccessRights(cursor = '', userGroupId = null, string = '', lockGroup= null, rootLockGroupIds, allUserGroupAccess)
    {
        let jsonArr = JSON.parse(rootLockGroupIds)

        let queryParam = '(userGroupId: "' + userGroupId;
        if(cursor && cursor !== ''){
            queryParam = queryParam + '", cursor: "' + cursor
        }
        if(string !== ''){
            queryParam = queryParam + '", filter_string: "' + string
        }
        if(lockGroup !== null) {
            queryParam = queryParam + '", lockGroupId: "' + lockGroup.id
        } else {
            queryParam = queryParam + '", rootLockGroupIds: "' + jsonArr
        }
        if(allUserGroupAccess === true){
            queryParam = queryParam + '", allUserGroupAccess: true)'
        } else {
            queryParam = queryParam + '")'
        }


        const query = gql`
            { boxAndAccessList${queryParam}

            {
                userAccessList {
                    id
                    first_name
                    last_name
                    company
                    phone
                    locks {
                        id
                        can_manage
                    }
                    lock_groups {
                        id
                        access
                        user_id
                    }
                }
                userGroupAccessList {
                    id,
                    name,
                    parent_id,
                    lock_groups {
                        id
                        access
                    }
                    locks {
                        id
                        can_manage
                    }
                }
                cursors {
                    prev
                    next
                    all
                }
            }}
        `
        return this.graphqlRequest(query, null);
    }


    static updateAccessList(accessList)
    {

        let body = '';
        accessList.forEach(dataitem => {
            let items = [];
            dataitem.locks.forEach(box => {
                if (box.id.charAt(0).toLowerCase() !== 'g') {
                    items.push(`{
                        boxId: ` + box.id + `,
                        hasAccess: ` + box.can_access + `,
                        canManage: ` + box.can_manage + `
                    }`);
                } else {
                    items.push(`{
                        lockGroupId: ` + box.id.substring(1) + `,
                        hasAccess: ` + box.can_access + `,
                        canManage: ` + box.can_manage + `
                    }`);
                }
            })
            if(dataitem.id.charAt(0).toLowerCase() !== 'u'){
                body += `
                query` + dataitem.id + `: updateAccessesForUser(
                        userId: ` + dataitem.id + `,
                        accessList: [` + items.join(',') + `]
                    ) 
                `;
            } else {
                body += `
                query` + dataitem.id + `: updateAccessesForUser(
                        userGroupId: ` + dataitem.id.substring(3) + `,
                        accessList: [` + items.join(',') + `]
                    ) 
                `;
            }


        });


        return this.graphqlRequest('mutation{' + body + '}');
    }

    static addUser(user)
    {
        const query = `
            mutation (
                $userInput: UserInput!
            ) {
              addUserToMyOrganization(
                user: $userInput
              ) {
                phone
                id
              }
            }  
        `;

        return this.graphqlRequest(query, {userInput: user});
    }

    static saveNewLockGroup(groupName, parentId){

        const query = `
        mutation(
            $name: String!
            $parent_id: ID
        ) {
            addLockGroupToMyOrganization(name: $name, parent_id: $parent_id) {
                id
                user_id
                name
                company
                parent_id
                root_group_id
                hierarchy_level
            }
        }`;

        return this.graphqlRequest(query, {
            name: groupName,
            parent_id: parentId,
        });
    }

    static saveNewUserGroup(groupName, parentId){

        const query = `
        mutation(
            $name: String!
            $parent_id: ID
        ) {
            addUserGroupToMyOrganization(name: $name, parent_id: $parent_id) {
                id
                user_id
                name
            }
        }`;

        return this.graphqlRequest(query, {
            name: groupName,
            parent_id: parentId,
        });
    }

    static updateLockGroup(group){
        const query = `
        mutation (
            $lockGroupId: ID!
            $name: String!
        ) {
            updateLockGroupById(
                lockGroupId: $lockGroupId
                name: $name
            ) 
        }`;

        return this.graphqlRequest(query, {
            lockGroupId: group.id,
            name: group.name,
        });
    }

    static updateUserGroup(group){
        const query = `
        mutation (
            $userGroupId: ID!
            $name: String!
        ) {
            updateUserGroupById(
                userGroupId: $userGroupId
                name: $name
            ) 
        }`;

        return this.graphqlRequest(query, {
            userGroupId: group.id,
            name: group.name,
        });
    }

    static deleteLockGroup(group){
        const query = `
            mutation (
                $lockGroupId: ID!
            ) {
              deleteLockGroupById(
                lockGroupId: $lockGroupId
              ) 
            }  
        `;

        return this.graphqlRequest(query, {lockGroupId: group.id});
    }

    static deleteUserGroup(group){
        const query = `
            mutation (
                $userGroupId: ID!
            ) {
              deleteUserGroupById(
                userGroupId: $userGroupId
              ) 
            }  
        `;

        return this.graphqlRequest(query, {userGroupId: group.id});
    }

    static changeLockName(lock){

        const query = `
        mutation (
            $id: ID!
            $name: String!
            $lockGroupId: ID!
        ) {
            changeLockName(
                id: $id
                name: $name
                lockGroupId: $lockGroupId
            ) 
        }`;

        return this.graphqlRequest(query, {
            id: lock.id,
            name: lock.name,
            lockGroupId: lock.lock_group_id
        });
    }

    static updateUser(user){
        const query = `
            mutation (
                $userId: ID!,
                $userDetail: UserDetailInput!
            ) {
              updateUser(
                userId: $userId,
                userInput: $userDetail
              ) 
            }  
        `;

        return this.graphqlRequest(query, {
            userId: user.id,
            userDetail: {
                'first_name': user.first_name,
                'last_name': user.last_name,
                'company': user.company,
            }
        });
    }

    static deleteUser(userId)
    {
        const query = `
            mutation (
                $id: ID!
            ) {
              removeUserFromLocksAndContacts(
                id: $id
              ) 
            }  
        `;
        return this.graphqlRequest(query, {id: userId});
    }

    static async graphqlRequest(query, variables, headers) {
        let requestHeaders = {Authorization: 'Bearer ' + SessionService.getAccessToken()};
        if (headers) {
            requestHeaders = {requestHeaders, headers};
        }
        try {
            const data = await request(
                process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_GRAPHQL_PATH,
                query,
                variables,
                requestHeaders
            );
            return data;
        } catch (error) {
            console.log(JSON.stringify(error, undefined, 2))
            if (!error.response) {
                this.emit('NETWORK_ERROR');
            }
            try {
                if (error.response.errors[0].message === 'Unauthenticated.') {
                    SessionService.deleteSession();
                    this.$router.push({ name: 'login-view' });
                }
            } catch(e) {
                throw error;
            }

            throw error;
        }
    }

    static on = eventstop.on;
    static off = eventstop.off;
    static emit = eventstop.emit;
}
