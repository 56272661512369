<template>
  <div>
    <Spinner v-if="loading"/>
    <div class="header_lock_group_content">
      <div class="lock_group_header">
        <div class="lock_group_title">
          {{ $t('HOME.USER_GROUPS') }}
        </div>
        <div @click="openUserGroupCreateModal"  class="lock_group_create_button">
          <span class="plus_icon">+</span><span class="lock_group_create_button_text">{{ $t('HOME.GROUP') }}</span>
        </div>
      </div>
    </div>

    <div class="lock_group_content">
      <div class="lock_group_container">
        <div class="lock_group_list">
          <user-group-list
              :userGroupSelected="userGroupSelected"
              :userGroups="userGroups"
              @selectUserGroup="selectUserGroup"
              @createSubGroup="createSubGroup"
          >

          </user-group-list>
        </div>
        <div class="user_group_details">
          <div class="lock_group_details_name">
            <span v-if="userGroupSelected === false" class="lock_group_details_name_text">{{ $t('HOME.ALL_USERS') }}</span>
            <span v-if="userGroupSelected !== false" class="lock_group_details_name_text">{{userGroupSelected.name}}</span>
            <span v-if="userGroupSelected !== false" @click="openUserGroupEditModal" class="pointer lock_group_details_name_image"><img src="../../assets/icon_edit.svg"/></span>
            <span v-if="userGroupSelected !== false" @click="openAddUsersToGroupModal" class="pointer add_lock_modal_link">
                 <img :src="addLockButton()"/>
             </span>
          </div>

          <div v-for="(user, index) in userListForAGroup(userGroupSelected)" :key="'L'+user.id+index">
            <div class="lock_list_item lock_list_item_page" :class="{'lock_list_item_even': index % 2 === 0, 'lock_list_item_odd': index % 2 !== 0}">
              <div class="lock_list_name_image">
                <img src="../../assets/userBak.svg"/>
                  <div class="user_list_name_span">{{user.name}}</div>
                  <div class="user_list_name_span">{{user.company}}</div>
                  <div class="user_list_name_span">{{user.phone}}</div>
              </div>

              <div class="lock_group_parent_name" v-if="user.user_group_id && user.user_group_id !== userGroupSelected.id">
                {{ userGroupName(user.user_group_id) }}
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-if="showUserGroupCreateModal && !loading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <img @click="closeUserGroupCreateModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot v-if="parentIdForUserGroup === null" name="header">
              {{ $t('HOME.USER_GROUP_SELECT.NEW_HEADER') }}
            </slot>
            <slot v-if="parentIdForUserGroup !== null" name="header">
              {{ $t('HOME.USER_GROUP_SELECT.NEW_HEADER_SUB') }}
            </slot>
          </div>
          <div class="modal-body">
            <div>
              <div class="new-user-info">
                <slot v-if="parentIdForUserGroup === null" name="body">
                  {{ $t('HOME.USER_GROUP_SELECT.INFO') }} <br/>
                </slot>
                <slot v-if="parentIdForUserGroup !== null" name="body">
                  {{ $t('HOME.USER_GROUP_SELECT.INFO_SUB') }} <br/>
                </slot>
              </div>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.USER_GROUP_SELECT.GROUP_NAME') }}</label>
                <md-input
                    maxlength="32"
                    v-model="newUserGroupName"
                >
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <img @click="saveNewUserGroup" :class="{'pointer':newUserGroupValid()}" class="save_user_button" :src="saveNewUserGroupButton()"/>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showUserGroupEditModal && !loading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <img @click="closeUserGroupEditModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot v-if="tempUserGroupSelected.parent_id === null" name="header">
              {{ $t('HOME.USER_GROUP_SELECT.EDIT_HEADER') }}
            </slot>
            <slot v-if="tempUserGroupSelected.parent_id !== null"  name="header">
              {{ $t('HOME.USER_GROUP_SELECT.EDIT_HEADER_SUB') }}
            </slot>
          </div>
          <div class="modal-body">
            <div>

              <div class="new-user-info">
                <slot v-if="tempUserGroupSelected.parent_id === null" name="body">
                  {{ $t('HOME.USER_GROUP_SELECT.INFO') }}
                </slot>
                <slot v-if="tempUserGroupSelected.parent_id !== null" name="body">
                  {{ $t('HOME.USER_GROUP_SELECT.INFO_SUB') }}
                </slot>
              </div>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.USER_GROUP_SELECT.GROUP_NAME') }}</label>
                <md-input
                    maxlength="32"
                    v-model="tempUserGroupSelected.name"
                >
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <span @click="openDeleteUserGroupModal" class="delete-group-line">{{ $t('HOME.USER_GROUP_SELECT.DELETE_USER_GROUP') }}</span>
              <img @click="updateUserGroup" :class="{'pointer':oldUserGroupValid()}" class="save_user_button" :src="saveOldUserGroupButton()"/>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDeleteGroupModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-delete">

          <div class="modal-header">
            <img @click="closeDeleteGroupModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot name="header">
              {{ $t('USERS_GROUPS.DELETE_HEADER') }}
            </slot>
          </div>

          <div class="modal-body">
            <div class="new-user-info">
              <slot name="body">
                <div>{{deleteGroupModalBody}}</div><br/>
                <div>{{ $t('USERS_GROUPS.CHOSEN_NOT_DELETED') }}</div>
              </slot>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="action-modal">
                <div @click="deleteUserGroup" class="link-color action-link">
                  {{ $t('HOME.DELETE') }}
                </div>
                <div>
                  <img @click="closeDeleteGroupModal" class="pointer save_user_button" :src="cancelButton()"/>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showAddUsersToGroupModal && !loading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container add-user-modal-container">
          <div class="modal-header">
            <img @click="closeAddUsersToGroupModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot name="header">
              {{ $t('HOME.USER_GROUP_SELECT.ADD_USERS_TO_GROUP') }}
            </slot>
          </div>
          <div class="modal-body">
            <div class="new-user-info">
              <slot name="body">
                {{ $t('HOME.USER_GROUP_SELECT.ADD_USERS_INFO') }}
              </slot>
            </div>
            <div>
              <div class="search-trigger-container">
                <div class="search-trigger-icon" >
                  <img src="../../assets/search.svg"/>
                </div>
                <div class="search-trigger-input">
                  <md-field :md-counter="false">
                    <label>{{ $t('HOME.SEARCH') }}</label>
                    <md-input
                        maxlength="32"
                        v-model="inputSearchForUsers"
                    >
                    </md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="scrollable-content">
              <div v-for="(item, index) in listOfUsersInModal()" :key="item.id">
                <div class="lock_list_item item_expanded" :class="{'lock_list_item_even': index % 2 === 0, 'lock_list_item_odd': index % 2 !== 0}">
                  <label class="form-control">
                    <input type="checkbox" v-model="item.selected"  @change="handleCheckboxChange(item.id)">
                    <div class="checkbox_style">
                      <div>
                        <div class="user_group_list_name">
                          <div class="user_group_list_element">{{item.name}}</div>
                          <div class="user_group_list_element">{{item.company}}</div>
                          <div class="user_group_list_element">{{item.phone}}</div>

                        </div>
                        <div v-if="item.user_group_id && item.user_group_id !== userGroupSelected.id" class="already_text">
                          <div v-if="userWillBeAddedToTheGroup(item.id)">
                            {{ $t('LOCKS_GROUPS.WILL_BE_MOVED_HERE') }}
                          </div>
                          <div v-if="!userWillBeAddedToTheGroup(item.id)">{{ $t('USERS_GROUPS.ALREADY_IN_A_GROUP') }}</div>
                        </div>
                        <div v-if="item.user_group_id && item.user_group_id === userGroupSelected.id" class="already_text">
                          <div v-if="userWillBeRemovedFromGroup(item.id)">{{ $t('USERS_GROUPS.WILL_BE_REMOVED') }}</div>
                          <div v-if="!userWillBeRemovedFromGroup(item.id)">{{ $t('USERS_GROUPS.ALREADY_IN_THE_GROUP') }}</div>
                        </div>
                      </div>
                      <div class="lock_group_parent_name_modal" v-if="item.user_group_id && item.user_group_id !== userGroupSelected.id">
                        {{ userGroupName(item.user_group_id) }}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <img @click="addUsersToGroup" :class="{'pointer':enableSaveUserAction()}" class="save_user_button" :src="saveButtonModal()"/>
            </slot>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Spinner from "../element/Spinner"
import UserGroupList from "@/components/element/UserGroupList";
import ApiService from "@/service/ApiService";


export default {
  name: 'UserGroupTab',
  components: {
    UserGroupList,
    Spinner
  },
  data () {
    return {
      loading: false,
      showUserGroupCreateModal: false,
      showUserGroupEditModal: false,
      showDeleteGroupModal: false,
      showAddUsersToGroupModal: false,
      deleteGroupModalBody: '',
      newUserGroupName: "",
      parentIdForUserGroup: null,
      userGroupSelected: false,
      userGroupSelectedId: false,
      tempUserGroupSelected: false,
      userGroups: false,
      complete_user_list: [],
      userObjectsSelectionArraySaveDataAdd: [],
      userObjectsSelectionArraySaveDataRemove: [],
      userObjectsSelectionArrayFirst: [],
      userObjectsSelectionArrayOriginalData: [],
      inputSearchForUsers: ""
    }
  },
  created() {
    this.getCompleteUserGroupList()
    this.getUserList()
  },
  methods:{
    userWillBeAddedToTheGroup(id){
      return (this.userObjectsSelectionArraySaveDataAdd.includes(Number(id)))
    },
    userWillBeRemovedFromGroup(id){
      return (this.userObjectsSelectionArraySaveDataRemove.includes(Number(id)))
    },
    handleCheckboxChange(){
      this.userObjectsSelectionArraySaveDataAdd = []
      this.userObjectsSelectionArraySaveDataRemove = []
      this.userObjectsSelectionArrayFirst.forEach((user)=>{
        let oldUserStatus = this.userObjectsSelectionArrayOriginalData.find(el=>el.id === user.id)
        if(user.selected === true && oldUserStatus.selected === false){
          this.userObjectsSelectionArraySaveDataAdd.push(Number(user.id))
        }
        if(user.selected === false && oldUserStatus.selected === true){
          this.userObjectsSelectionArraySaveDataRemove.push(Number(user.id))
        }
      })
      this.$forceUpdate();
    },
    addUsersToGroup(){
      if(this.enableSaveUserAction()){
        this.loading = true
        ApiService.updateUserGroupForUsers(this.tempUserGroupSelected.id, this.userObjectsSelectionArraySaveDataAdd, this.userObjectsSelectionArraySaveDataRemove).then(()=>{
          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.CHANGES_SAVED')
          })
        }).catch((error)=>{
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.SAVE_FAILED')
          })
          console.log(error)
        }).finally(()=>{
          this.showAddUsersToGroupModal = false
          this.loading = false
          this.getCompleteUserGroupList()
          this.getUserList()
          this.$emit('setNeedToUpdateLocalData')
        })
      }
    },
    enableSaveUserAction(){
      return (this.userObjectsSelectionArraySaveDataAdd.length > 0 || this.userObjectsSelectionArraySaveDataRemove.length > 0) && !this.loading && !this.tableLoading
    },
    userGroupName: function(id){
      if(this.userGroups === false){
        return ''
      }
      let group = this.userGroups.find(l => l.id === id)
      if (group && group.name) {
        if (group.name.length > 30) {
          group.name = group.name.slice(0, 30 - 3) + '...';
        }
        return group.name
      }
      return ''
    },
    listOfUsersInModal(){
      if(this.inputSearchForUsers === ""){
        return this.userObjectsSelectionArrayFirst
      }
      let filteredList = this.userObjectsSelectionArrayFirst.filter(el=>(el.name.includes(this.inputSearchForUsers) || el.company.includes(this.inputSearchForUsers) || el.phone.includes(this.inputSearchForUsers)))
      return filteredList
    },
    closeAddUsersToGroupModal: function() {
      this.showAddUsersToGroupModal = false;
    },
    openAddUsersToGroupModal: function(){
      this.filterUsersForAddLockModal(this.userGroupSelected);
      this.showAddUsersToGroupModal = true;
    },
    filterUsersForAddLockModal: function(group){
      this.userObjectsSelectionArraySaveDataAdd = []
      this.userObjectsSelectionArraySaveDataRemove = []
      this.userObjectsSelectionArrayFirst = JSON.parse(JSON.stringify(this.complete_user_list))
      this.userObjectsSelectionArrayFirst.forEach((user)=>{
        user.selected = (user.user_group_id === group.id)
      })
      this.userObjectsSelectionArrayFirst.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      this.userObjectsSelectionArrayOriginalData = JSON.parse(JSON.stringify(this.userObjectsSelectionArrayFirst))
    },
    deleteUserGroup: function(){
      this.loading = true;
      this.showDeleteGroupModal = false;
      ApiService.deleteUserGroup(this.userGroupSelected).then(()=>{
        this.userGroupSelected = false;
        this.userGroupSelectedId = false;
        this.tempUserGroupSelected = false;
        this.$root.$emit('SHOW_SNACKBAR', {
          message: this.$i18n.t('HOME.MESSAGES.GROUP_DELETED')
        })
        this.$emit('setNeedToUpdateLocalData')
        this.getCompleteUserGroupList()
        this.getUserList()
      }).catch((error)=>{
        console.log(error)
      }).finally(()=>{
        this.loading = false
      })
    },
    closeDeleteGroupModal: function() {
      this.openUserGroupEditModal()
      this.showDeleteGroupModal = false;
    },
    userListForAGroup: function(group){
      if(!group){
        return this.complete_user_list
      }
      let arrayOfGroupsIds = []
      arrayOfGroupsIds.push(group.id)
      let subgroups = this.filterByParentId(group.id).map(obj => obj.id);
      arrayOfGroupsIds = arrayOfGroupsIds.concat(subgroups)
      let lockListForGroup = this.complete_user_list.filter(obj => arrayOfGroupsIds.includes(obj.user_group_id))
      lockListForGroup.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      return lockListForGroup
    },
    filterByParentId: function(parentId){
      let children = this.userGroups.filter(obj => obj.parent_id === parentId)
      children.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      return children;
    },
    openDeleteUserGroupModal: function() {
      this.closeUserGroupEditModal()
      this.deleteGroupModalBody = this.$i18n.t('USERS_GROUPS.DELETE_TEXT', {
        groupName: this.userGroupSelected.name,
      })
      this.showDeleteGroupModal = true;
    },
    updateUserGroup: function() {
      if(this.oldUserGroupValid()){
        this.loading = true;
        ApiService.updateUserGroup(this.tempUserGroupSelected).then(()=>{
          let group = this.userGroups.find(el => el.id === this.tempUserGroupSelected.id)
          group.name = this.tempUserGroupSelected.name
          this.userGroups.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
        }).catch((error)=>{
          console.log(error)
        }).finally(()=>{
          this.closeUserGroupEditModal()
          this.loading = false
        })
      }
    },
    oldUserGroupValid: function() {
      return (this.tempUserGroupSelected.name.trim().length > 2 )
    },
    saveOldUserGroupButton: function() {
      if(this.oldUserGroupValid()){
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    openUserGroupEditModal: function (){
      this.showUserGroupEditModal = true;
    },
    closeUserGroupEditModal: function(){
      this.showUserGroupEditModal = false;
    },
    createSubGroup: function (){
      this.parentIdForUserGroup = this.userGroupSelected.id
      this.showUserGroupCreateModal = true;
    },
    selectUserGroup: function(group){
      if(!group){
        this.userGroupSelected = false
        this.userGroupSelectedId = false
        this.tempUserGroupSelected = false
      } else {
        this.filterUsersForAddUserModal(group)
        this.userGroupSelected = group
        this.userGroupSelectedId = group.id
        this.tempUserGroupSelected = JSON.parse(JSON.stringify(group))
      }
    },
    filterUsersForAddUserModal: function(){},
    getUserList: function(){
      ApiService.getCompleteUserList().then((result)=>{
        this.complete_user_list = result.usersICanManage.users
        this.complete_user_list.forEach((usr)=>{
          usr.name = this.parse_name(usr)
        })
        this.complete_user_list.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      })
    },
    parse_name: function (usr) {
      let parsed_name = usr.last_name;
      if (usr.first_name) {
        parsed_name = parsed_name + ' ' + usr.first_name
      }
      if (parsed_name === '') {
        parsed_name = usr.phone
      }
      return parsed_name;
    },
    getCompleteUserGroupList: function(){
      this.loading = true;
      ApiService.getAllUserGroupsIHaveAccessTo().then((result)=>{
        this.userGroups = result.allUserGroupsIHaveAccessTo
        this.userGroups.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      }).catch((error)=>{
        console.log(error)
      }).finally(()=>{
        this.loading = false
      })
    },
    openUserGroupCreateModal: function(){
      this.newUserGroupName = ""
      this.parentIdForUserGroup = null
      this.showUserGroupCreateModal = true;
    },
    closeUserGroupCreateModal: function(){
      this.showUserGroupCreateModal = false;
    },
    saveNewUserGroup: function(){
      if(this.newUserGroupValid()){
        this.loading = true;
        let newGroup = false;
        ApiService.saveNewUserGroup(this.newUserGroupName.trim(), this.parentIdForUserGroup).then((result)=>{
          newGroup = result.addUserGroupToMyOrganization;
          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.GROUP_CREATED')
          })
          this.showUserGroupCreateModal = false;
          this.getCompleteUserGroupList()
        }).catch((error)=>{
          console.log(error)
          this.loading = false;
        }).finally(()=>{
          if(newGroup){
            this.selectUserGroup(newGroup)
            this.openAddUsersToGroupModal()
          }
        })
      }
    },
    newUserGroupValid: function(){
      return this.newUserGroupName.trim().length > 2
    },
    cancelButton: function () {
      if (this.$i18n.locale === 'de') {
        return require("../../assets/cancel_button_de.svg");
      }
      return require("../../assets/cancel_button.svg");
    },
    saveNewUserGroupButton: function(){
      if(this.newUserGroupValid()){
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    addLockButton: function (){
      if (this.$i18n.locale === 'de') {
        return require("../../assets/add_user_de.svg");
      }
      return require("../../assets/add_user_en.svg");
    },
    saveButtonModal: function(){
      if (this.enableSaveUserAction()) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
  }
}
</script>


<style scoped>
.search-trigger-container{
  display: flex;
  justify-content: left;
}
.search-trigger-icon{
  padding-top: 28px;
  padding-left: 8px;
  width: 30px;
}
.search-trigger-input{
  margin-left: 20px;
  width: 323px;
}
.scrollable-content{
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -30px;
  margin-right: -30px;
}
.lock_group_container{
  display: flex;
  padding-top: 40px;
  min-width: 960px;
  height: 100%;
}
.lock_group_content {
  position: absolute;
  top: 135px;
  padding-bottom: 50px;
  width: 100%;
  height: calc(100% - 135px);
}
.lock_group_list {
  width: 280px;
  max-height: 100%;
  overflow: auto;
}
.user_group_details {
  padding-right: 60px;
  width: 100%;
  margin-left: 30px;
  max-height: 100%;
  overflow: auto;
}
.header_lock_group_content{
  z-index: 100;
  background-color: white;
  position: fixed;
  top: 75px;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.add-user-modal-container{
  width: 900px;
}

.lock_group_title{
  padding-left: 25px;
  font-size: 24px;
  font-weight: 600;
}

.lock_group_create_button{
  padding-left: 18px;
  color: #c2a977;
  font-weight: 600;
  font-size: 16px;
}

.lock_group_details_name{
  border-bottom: 1px solid  #c2a977;
  padding: 0px 0px 25px 40px;
  font-size: 18px;
  font-weight: 600;
}
.lock_group_details_name_text{
  padding-right: 15px;
  padding-top: 16px;
  display: inline-block;
}
.lock_list_item_page{
  font-weight: 600;
  justify-content: space-between;
}
.lock_group_details_name_image{
  padding-top: 16px;
}
.add_lock_modal_link{
  float: right;
}
.plus_icon{
  font-size: 24px;
  padding-right: 8px;
  float: left;
}
.lock_group_create_button_text:hover{
  text-decoration: underline;
  cursor: pointer;
}

.lock_group_header{
  display: flex;
}

.lock_list_item{
  padding: 25px 10px 25px 40px;
  font-size: 16px;
  display: flex;
}
.lock_group_parent_name{
  border: 1px solid black;
  padding: 0px 10px;
  margin: 0;
  display: table;
  border-radius: 22px;
  font-size: 12px;
  height: 10px;
  margin-top: 8px;
  color: #766A53;
  text-align: center;
  white-space: nowrap;
}
.user_group_list_name{
  display: flex;
  font-weight: 400;
  width: 600px;
}
.user_group_list_element{
  flex-basis: 100%;
  padding-right: 5px;
}
.already_text{
  color: #666666;
  font-size: 13px;
}
.checkbox_style{
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}
.lock_group_parent_name_modal{
  text-align: center;
  border: 1px solid black;
  padding: 0px 10px;
  margin: 0;
  display: table;
  border-radius: 22px;
  font-size: 12px;
  height: 10px;
  margin-top: 2px;
  color: #766A53 !important;
}
.lock_list_name_image{
  display: flex;
  width: 80%;
}
.user_list_name_span{
  flex-basis: 100%;
  text-align: left;
  padding-left: 20px;
  padding-top: 8px;
}
.user_list_name{
  margin-top: 8px;
  padding-left: 20px;
}
.lock_list_item_odd{
  background-color: white;
}
.lock_list_item_even{
  background-color: #FAF8F5;
}
.pointer {
  cursor: pointer;
}
.modal-delete .modal-body {
  margin-bottom: 15px;
}
.modal-delete .save_user_button {
  margin-bottom: -10px;
}
.delete-group-line{
  cursor: pointer;
  color: #c2a977;
  text-decoration: underline;
  float: left;
  font-size: 16px;
  font-weight: 600;
}
.item_expanded{
  /*margin-left: -30px;*/
  /*margin-right: -30px;*/
}
.link-color{
  color: #c2a977;
  font-weight: bold;
}
.action-modal {
  display: flex;
  justify-content: end;
  font-size: 16px;
}
.action-link {
  padding-top: 19px;
  cursor: pointer;
}
.form-control {
  font-family: system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #c2a977;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #c2a977;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #666666;
  /* Windows High Contrast Mode */
  background-color: #766a53;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>