import { render, staticRenderFns } from "./UserGroupList.vue?vue&type=template&id=63aae244&scoped=true&"
import script from "./UserGroupList.vue?vue&type=script&lang=js&"
export * from "./UserGroupList.vue?vue&type=script&lang=js&"
import style0 from "./UserGroupList.vue?vue&type=style&index=0&id=63aae244&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63aae244",
  null
  
)

export default component.exports