<template>
  <div>
    <Spinner v-if="loading"/>
    <div class="header_lock_group_content">
      <div class="lock_group_header">
        <div class="lock_group_title">
          {{ $t('HOME.LOCK_GROUPS') }}
        </div>
        <div @click="openLockGroupCreateModal"  class="lock_group_create_button">
          <span class="plus_icon">+</span><span class="lock_group_create_button_text">{{ $t('HOME.GROUP') }}</span>
        </div>
      </div>
    </div>
    <div class="lock_group_content">
      <div class="lock_group_container">
        <div class="lock_group_list">

          <lock-group-list
              :locksGroups="locksGroups"
              :lockGroupSelected="lockGroupSelectedObj"
              @selectLockGroup="selectLockGroup"
              @createSubGroup="createSubGroup"
          />

        </div>
        <div class="lock_group_details">
          <div class="lock_group_details_name">
            <span v-if="lockGroupSelectedObj === false" class="lock_group_details_name_text">{{ $t('HOME.ALL_ROOMS') }}</span>
            <span v-if="lockGroupSelectedObj !== false" class="lock_group_details_name_text">{{lockGroupSelectedObj.name}}</span>
            <span v-if="lockGroupSelectedObj !== false" @click="openLockGroupEditModal" class="pointer lock_group_details_name_image"><img src="../../assets/icon_edit.svg"/></span>
            <span v-if="lockGroupSelectedObj !== false" @click="openAddLocksToGroupModal" class="pointer add_lock_modal_link">
                   <img :src="addLockButton()"/>
               </span>
          </div>

          <div v-for="(lock, index) in lockListForAGroup(lockGroupSelectedObj)" :key="'L'+lock.id+index">
            <div class="lock_list_item lock_list_item_page" :class="{'lock_list_item_even': index % 2 === 0, 'lock_list_item_odd': index % 2 !== 0}">
              <div class="lock_list_name_image">
                <img src="../../assets/room.svg"/>
                <div class="lock_list_name">{{lock.name}}</div>
              </div>

              <div class="lock_group_parent_name" v-if="lock.lock_group_id && lock.lock_group_id !== lockGroupSelectedObj.id">
                {{ lockGroupName(lock.lock_group_id) }}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showLockGroupCreateModal && !loading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <img @click="closeLockGroupCreateModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot v-if="parentIdForLockGroup === null" name="header">
              {{ $t('HOME.LOCK_GROUP_SELECT.NEW_HEADER') }}
            </slot>
            <slot v-if="parentIdForLockGroup !== null" name="header">
              {{ $t('HOME.LOCK_GROUP_SELECT.NEW_HEADER_SUB') }}
            </slot>
          </div>
          <div class="modal-body">
            <div>
              <div class="new-user-info">
                <slot v-if="parentIdForLockGroup === null" name="body">
                  {{ $t('HOME.LOCK_GROUP_SELECT.INFO') }} <br/>
                </slot>
                <slot v-if="parentIdForLockGroup !== null" name="body">
                  {{ $t('HOME.LOCK_GROUP_SELECT.INFO_SUB') }} <br/>
                </slot>
              </div>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.LOCK_GROUP_SELECT.GROUP_NAME') }}</label>
                <md-input
                    maxlength="32"
                    v-model="newLockGroupName"
                >
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <img @click="saveNewLockGroup" :class="{'pointer':newLockGroupValid()}" class="save_user_button" :src="saveNewLockGroupButton()"/>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showLockGroupEditModal && !loading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <img @click="closeLockGroupEditModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot v-if="tempLockGroupSelected.parent_id === null" name="header">
              {{ $t('HOME.LOCK_GROUP_SELECT.EDIT_HEADER') }}
            </slot>
            <slot v-if="tempLockGroupSelected.parent_id !== null"  name="header">
              {{ $t('HOME.LOCK_GROUP_SELECT.EDIT_HEADER_SUB') }}
            </slot>
          </div>
          <div class="modal-body">
            <div>

              <div class="new-user-info">
                <slot v-if="tempLockGroupSelected.parent_id === null" name="body">
                  {{ $t('HOME.LOCK_GROUP_SELECT.INFO') }}
                </slot>
                <slot v-if="tempLockGroupSelected.parent_id !== null" name="body">
                  {{ $t('HOME.LOCK_GROUP_SELECT.INFO_SUB') }}
                </slot>
              </div>
              <md-field :md-counter="false">
                <label>{{ $t('HOME.LOCK_GROUP_SELECT.GROUP_NAME') }}</label>
                <md-input
                    maxlength="32"
                    v-model="tempLockGroupSelected.name"
                >
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <span @click="openDeleteGroupModal" class="delete-group-line">{{ $t('HOME.LOCK_GROUP_SELECT.DELETE_LOCK_GROUP') }}</span>
              <img @click="updateLockGroup" :class="{'pointer':oldLockGroupValid()}" class="save_user_button" :src="saveOldLockGroupButton()"/>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddLocksToGroupModal && !loading" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <img @click="closeAddLocksToGroupModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot name="header">
              {{ $t('HOME.LOCK_GROUP_SELECT.ADD_LOCKS_TO_GROUP') }}
            </slot>
          </div>
          <div class="modal-body">
            <div class="new-user-info">
              <slot name="body">
                {{ $t('HOME.LOCK_GROUP_SELECT.ADD_LOCKS_INFO') }}
              </slot>
            </div>
            <div>
                <div class="search-trigger-container">
                  <div class="search-trigger-icon" >
                    <img src="../../assets/search.svg"/>
                  </div>
                  <div class="search-trigger-input">
                    <md-field :md-counter="false">
                    <label>{{ $t('HOME.SEARCH') }}</label>
                    <md-input
                        maxlength="32"
                        v-model="inputSearchForLocks"
                    >
                    </md-input>
                    </md-field>
                  </div>
                </div>
            </div>
            <div class="scrollable-content">
              <div v-for="(item, index) in listOfLocksInModal()" :key="'I'+item.id+index">
                <div class="lock_list_item item_expanded" :class="{'lock_list_item_even': index % 2 === 0, 'lock_list_item_odd': index % 2 !== 0}">
                  <label class="form-control">
                    <input type="checkbox" v-model="item.selected"  @change="handleCheckboxChange(item.id)">
                    <div class="checkbox_style">
                      <div>
                        <div class="lock_group_list_name">{{item.name}}</div>
                        <div v-if="item.lock_group_id && item.lock_group_id !== lockGroupSelectedObj.id" class="already_text">
                          <div v-if="lockWillBeAddedToTheGroup(item.id)">
                            {{ $t('LOCKS_GROUPS.WILL_BE_MOVED_HERE') }}
                          </div>
                          <div v-if="!lockWillBeAddedToTheGroup(item.id)">{{ $t('LOCKS_GROUPS.ALREADY_IN_A_GROUP') }}</div>
                        </div>
                        <div v-if="item.lock_group_id && item.lock_group_id === lockGroupSelectedObj.id" class="already_text">
                          <div v-if="lockWillBeRemovedFromGroup(item.id)">{{ $t('LOCKS_GROUPS.WILL_BE_REMOVED') }}</div>
                          <div v-if="!lockWillBeRemovedFromGroup(item.id)">{{ $t('LOCKS_GROUPS.ALREADY_IN_THE_GROUP') }}</div>
                        </div>
                      </div>
                      <div class="lock_group_parent_name_modal" v-if="item.lock_group_id && item.lock_group_id !== lockGroupSelectedObj.id">
                        {{ lockGroupName(item.lock_group_id) }}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
                <img @click="addLocksToGroup" :class="{'pointer':enableSaveLockAction()}" class="save_user_button" :src="saveButtonModal()"/>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDeleteGroupModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-delete">

          <div class="modal-header">
            <img @click="closeDeleteGroupModal" class="modal_close" src="../../assets/cancel.svg"/>
            <slot name="header">
              {{ $t('LOCKS_GROUPS.DELETE_HEADER') }}
            </slot>
          </div>

          <div class="modal-body">
            <div class="new-user-info">
              <slot name="body">
                <div>{{deleteGroupModalBody}}</div><br/>
                <div>{{ $t('LOCKS_GROUPS.CHOSEN_NOT_DELETED') }}</div>
              </slot>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="action-modal">
                <div @click="deleteLockGroup" class="link-color action-link">
                  {{ $t('HOME.DELETE') }}
                </div>
                <div>
                  <img @click="closeDeleteGroupModal" class="pointer save_user_button" :src="cancelButton()"/>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LockGroupList from "@/components/element/LockGroupList";
import ApiService from "@/service/ApiService";
import Spinner from "../element/Spinner"

export default {
  components: {
    LockGroupList,
    Spinner
    },
  name: 'LockGroupTab',
  props: ['locksGroups'],
  data () {
    return {
      showLockGroupCreateModal: false,
      showLockGroupEditModal: false,
      parentIdForLockGroup: null,
      lockGroupSelectedObj: false,
      lockGroupSelectedId: false,
      tempLockGroupSelected: false,
      showAddLocksToGroupModal: false,
      showDeleteGroupModal: false,
      deleteGroupModalBody: '',
      newLockGroupName: "",
      lockObjectsSelectionArrayFirst: [],
      lockObjectsSelectionArrayOriginalData: [],
      lockObjectsSelectionArraySaveDataAdd: [],
      lockObjectsSelectionArraySaveDataRemove: [],
      loading: false,
      inputSearchForLocks: ""
    }
  },
  created() {
    this.getCompleteLockList()
  },
  methods: {
    getCompleteLockList() {
      this.loading = true
      ApiService.getCompleteLockList().then(result => {
        this.complete_lock_list = result.boxesICanManage.boxes
      }).catch((err)=>{
        console.log(err)
      }).finally(()=>{
        this.loading = false
      })
    },
    listOfLocksInModal(){
      if(this.inputSearchForLocks === ""){
        return this.lockObjectsSelectionArrayFirst
      }
      let filteredList = this.lockObjectsSelectionArrayFirst.filter(el=>el.name.includes(this.inputSearchForLocks))
      return filteredList
    },
    saveNewLockGroup: function (){
      if(this.newLockGroupValid()){
        this.loading = true;
        let newGroup = false;
        ApiService.saveNewLockGroup(this.newLockGroupName.trim(), this.parentIdForLockGroup).then((result)=>{
          newGroup = result.addLockGroupToMyOrganization;
          if(newGroup){
            this.$emit('addGroupToLocksGroups', result.addLockGroupToMyOrganization)
            this.$root.$emit('SHOW_SNACKBAR', {
              message: this.$i18n.t('HOME.MESSAGES.GROUP_CREATED')
            })
          }
        }).catch((er)=>{
          console.log('new group NOT saved')
          console.log(er)
        }).finally(()=>{
          this.loading=false;
          this.closeLockGroupCreateModal()
          if(newGroup){
            this.selectLockGroup(newGroup)
            this.openAddLocksToGroupModal()
          }
        })
      }
    },
    deleteLockGroup: function () {
      this.loading = true;
      this.showDeleteGroupModal = false;
      ApiService.deleteLockGroup(this.lockGroupSelectedObj).then(()=>{
        this.showLockGroupEditModal = false
        this.$emit('removeGroupToLocksGroups', this.lockGroupSelectedObj.id)
        this.$root.$emit('SHOW_SNACKBAR', {
          message: this.$i18n.t('HOME.MESSAGES.GROUP_DELETED')
        })
        this.lockGroupSelectedObj = false
      }).catch((er)=>{

        console.log(er)
        this.loading = false
      }).finally(()=>{
        this.getCompleteLockList();
        this.$emit('setNeedToUpdateLocalData')
      })
    },
    updateLockGroup: function () {
      if(this.oldLockGroupValid()){
        this.loading = true
        ApiService.updateLockGroup(this.tempLockGroupSelected).then(()=>{
          let group = this.locksGroups.find(l => l.id === this.lockGroupSelectedObj.id)
          this.lockGroupSelectedObj = JSON.parse(JSON.stringify(this.tempLockGroupSelected))
          group.name = this.lockGroupSelectedObj.name
          this.$emit('sortLocksGroups')
          this.showLockGroupEditModal = false
          this.$emit('setNeedToUpdateLocalData')
          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.CHANGES_SAVED')
          })
        }).catch((error)=>{
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.SAVE_FAILED')
          })
          console.log(error)
          console.log(error)
        }).finally(()=>{
          this.loading = false
        })
      }
    },
    handleCheckboxChange: function(){
      this.lockObjectsSelectionArraySaveDataAdd = []
      this.lockObjectsSelectionArraySaveDataRemove = []
      this.lockObjectsSelectionArrayFirst.forEach((lock)=>{
        let oldLockStatus = this.lockObjectsSelectionArrayOriginalData.find(el=>el.id === lock.id)
        if(lock.selected === true && oldLockStatus.selected === false){
          this.lockObjectsSelectionArraySaveDataAdd.push(Number(lock.id))
        }
        if(lock.selected === false && oldLockStatus.selected === true){
          this.lockObjectsSelectionArraySaveDataRemove.push(Number(lock.id))
        }
      })
      this.$forceUpdate();
    },
    lockWillBeAddedToTheGroup: function(id){
      return (this.lockObjectsSelectionArraySaveDataAdd.includes(Number(id)))
    },
    lockWillBeRemovedFromGroup: function(id){
      return (this.lockObjectsSelectionArraySaveDataRemove.includes(Number(id)))
    },
    addLocksToGroup: function(){
      if(this.enableSaveLockAction()){
        this.loading = true
        ApiService.updateLockGroupForBoxes(this.tempLockGroupSelected.id, this.lockObjectsSelectionArraySaveDataAdd, this.lockObjectsSelectionArraySaveDataRemove).then(()=>{
          this.$root.$emit('SHOW_SNACKBAR', {
            message: this.$i18n.t('HOME.MESSAGES.CHANGES_SAVED')
          })
        }).catch((error)=>{
          this.$root.$emit('SHOW_SNACKBAR', {
            error: error,
            message: this.$i18n.t('HOME.ERROR.SAVE_FAILED')
          })
          console.log(error)
        }).finally(()=>{
          this.showAddLocksToGroupModal = false
          this.loading = false
          this.getCompleteLockList();
          this.$emit('setNeedToUpdateLocalData')
        })
      }
    },
    createSubGroup: function (){
      this.parentIdForLockGroup = this.lockGroupSelectedObj.id
      this.showLockGroupCreateModal = true;
    },
    lockGroupName: function(id){
      let group = this.locksGroups.find(l => l.id === id)
      if (group && group.name) {
        return group.name
      }
      return ''
    },
    lockListForAGroup: function(group){
      if(!group){
        if(this.complete_lock_list && this.complete_lock_list.length > 0){
          this.complete_lock_list.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
        }
        return this.complete_lock_list
      }
      let arrayOfGroupsIds = []
      arrayOfGroupsIds.push(group.id)
      let subgroups = this.filterByParentId(group.id).map(obj => obj.id);
      arrayOfGroupsIds = arrayOfGroupsIds.concat(subgroups)
      let lockListForGroup = this.complete_lock_list.filter(obj => arrayOfGroupsIds.includes(obj.lock_group_id))
      lockListForGroup.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      return lockListForGroup
    },
    filterByParentId: function(parentId){
      let children = this.locksGroups.filter(obj => obj.parent_id === parentId)
      children.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      return children;
    },
    selectLockGroup: function(group){
      if(!group){
        this.lockGroupSelectedId = false
        this.lockGroupSelectedObj = false
        this.tempLockGroupSelected = false
        this.lockObjectsSelectionArrayFirst = JSON.parse(JSON.stringify(this.complete_lock_list))
      } else {
        this.filterLocksForAddLockModal(group)
        setTimeout(()=>{
          this.lockGroupSelectedId = group.id
          this.lockGroupSelectedObj = group
          this.tempLockGroupSelected = JSON.parse(JSON.stringify(group))
        }, 100);
      }
    },
    filterLocksForAddLockModal: function(group){
      this.lockObjectsSelectionArraySaveDataAdd = []
      this.lockObjectsSelectionArraySaveDataRemove = []
      this.lockObjectsSelectionArrayFirst = JSON.parse(JSON.stringify(this.complete_lock_list))
      this.lockObjectsSelectionArrayFirst.forEach((lock)=>{
        lock.selected = (lock.lock_group_id === group.id)
      })
      this.lockObjectsSelectionArrayFirst.sort((a,b) => ("" + a.name).localeCompare(b.name, undefined, {numeric: true}))
      this.lockObjectsSelectionArrayOriginalData = JSON.parse(JSON.stringify(this.lockObjectsSelectionArrayFirst))
    },
    newLockGroupValid: function (){
      let g = this.locksGroups.find(l => l.name === this.newLockGroupName)
      return (g === undefined && this.newLockGroupName.trim().length > 2)
    },
    oldLockGroupValid: function (){
      return (this.tempLockGroupSelected.name.trim().length > 2 )
    },
    //modals triggers
    openDeleteGroupModal: function() {
      this.closeLockGroupEditModal()
      this.deleteGroupModalBody = this.$i18n.t('LOCKS_GROUPS.DELETE_TEXT', {
        groupName: this.lockGroupSelectedObj.name,
      })
      this.showDeleteGroupModal = true;
    },
    closeDeleteGroupModal: function() {
      this.openLockGroupEditModal()
      this.showDeleteGroupModal = false;
    },
    openAddLocksToGroupModal: function(){
      this.filterLocksForAddLockModal(this.lockGroupSelectedObj);
      this.showAddLocksToGroupModal = true;
    },
    closeAddLocksToGroupModal: function(){
      this.showAddLocksToGroupModal = false;
    },
    openLockGroupEditModal: function(){
      this.showLockGroupEditModal = true;
    },
    closeLockGroupEditModal: function(){
      this.showLockGroupEditModal = false;
    },
    openLockGroupCreateModal: function(){
      this.parentIdForLockGroup = null
      this.newLockGroupName = ""
      this.showLockGroupCreateModal = true;
    },
    closeLockGroupCreateModal: function (){
      this.showLockGroupCreateModal = false;
    },
    //buttons
    cancelButton: function () {
      if (this.$i18n.locale === 'de') {
        return require("../../assets/cancel_button_de.svg");
      }
      return require("../../assets/cancel_button.svg");
    },
    enableSaveLockAction: function (){
      return (this.lockObjectsSelectionArraySaveDataAdd.length > 0 || this.lockObjectsSelectionArraySaveDataRemove.length > 0) && !this.loading && !this.tableLoading
    },
    saveButtonModal: function(){
      if (this.enableSaveLockAction()) {
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    saveOldLockGroupButton: function(){
      if(this.oldLockGroupValid()){
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
    addLockButton: function (){
      if (this.$i18n.locale === 'de') {
        return require("../../assets/add_de.svg");
      }
      return require("../../assets/add_en.svg");
    },
    saveNewLockGroupButton: function(){
      if(this.newLockGroupValid()){
        if (this.$i18n.locale === 'de') {
          return require("../../assets/save_button_enabled_de.svg");
        }
        return require("../../assets/save_button_enabled.svg");
      }
      if (this.$i18n.locale === 'de') {
        return require("../../assets/save_button_disabled_de.svg");
      }
      return require("../../assets/save_button_disabled.svg");
    },
  }
}
</script>

<style scoped>
.search-trigger-container{
  display: flex;
  justify-content: space-between;
}
.search-trigger-icon{
  padding-top: 28px;
  padding-left: 8px;
  width: 36px;
}
.search-trigger-input{
  width: 323px;
}
.scrollable-content{
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -30px;
  margin-right: -30px;
}
.lock_group_container{
  display: flex;
  padding-top: 40px;
  min-width: 960px;
  height: 100%;
}
.lock_group_content {
  position: absolute;
  top: 135px;
  padding-bottom: 50px;
  width: 100%;
  height: calc(100% - 135px);
}
.lock_group_list {
  width: 280px;
  max-height: 100%;
  overflow: auto;
}
.lock_group_details {
  width: 600px;
  margin-left: 30px;
  max-height: 100%;
  overflow: auto;
}
.header_lock_group_content{
  z-index: 100;
  background-color: white;
  position: fixed;
  top: 75px;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.lock_group_title{
  padding-left: 25px;
  font-size: 24px;
  font-weight: 600;
}

.lock_group_create_button{
  padding-left: 18px;
  color: #c2a977;
  font-weight: 600;
  font-size: 16px;
}

.lock_group_details_name{
  border-bottom: 1px solid  #c2a977;
  padding: 0px 0px 25px 40px;
  font-size: 18px;
  font-weight: 600;
}
.lock_group_details_name_text{
  padding-right: 15px;
  padding-top: 16px;
  display: inline-block;
}
.lock_list_item_page{
  font-weight: 600;
  justify-content: space-between;
}
.lock_group_details_name_image{
  padding-top: 16px;
}
.add_lock_modal_link{
  float: right;
}
.plus_icon{
  font-size: 24px;
  padding-right: 8px;
  float: left;
}
.lock_group_create_button_text:hover{
  text-decoration: underline;
  cursor: pointer;
}

.lock_group_header{
  display: flex;
}

.lock_list_item{
  padding: 25px 10px 25px 40px;
  font-size: 16px;
  display: flex;
}
.lock_group_parent_name{
  border: 1px solid black;
  padding: 0px 10px;
  margin: 0;
  display: table;
  border-radius: 22px;
  font-size: 12px;
  height: 10px;
  margin-top: 8px;
  color: #766A53;
}
.lock_group_list_name{
  font-weight: 400;
}
.already_text{
  color: #666666;
  font-size: 13px;
}
.checkbox_style{
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  width: 310px;
}
.lock_group_parent_name_modal{
  text-align: center;
  border: 1px solid black;
  padding: 0px 10px;
  margin: 0;
  display: table;
  border-radius: 22px;
  font-size: 12px;
  height: 10px;
  margin-top: 2px;
  color: #766A53 !important;
}
.lock_list_name_image{
  display: flex;
}
.lock_list_name{
  margin-top: 8px;
  padding-left: 20px;
}
.lock_list_item_odd{
  background-color: white;
}
.lock_list_item_even{
  background-color: #FAF8F5;
}
.pointer {
  cursor: pointer;
}
.modal-delete .modal-body {
  margin-bottom: 15px;
}
.modal-delete .save_user_button {
  margin-bottom: -10px;
}
.delete-group-line{
  cursor: pointer;
  color: #c2a977;
  text-decoration: underline;
  float: left;
  font-size: 16px;
  font-weight: 600;
}
.item_expanded{
  /*margin-left: -30px;*/
  /*margin-right: -30px;*/
}
.link-color{
  color: #c2a977;
  font-weight: bold;
}
.action-modal {
  display: flex;
  justify-content: end;
  font-size: 16px;
}
.action-link {
  padding-top: 19px;
  cursor: pointer;
}
.form-control {
  font-family: system-ui, sans-serif;
  font-size: 16px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #c2a977;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #c2a977;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #666666;
  /* Windows High Contrast Mode */
  background-color: #766a53;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>